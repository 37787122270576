import React from 'react'
import Layout from '../components/layout'
import { graphql } from'gatsby'
import Image from 'gatsby-image'
import PortableText from '@sanity/block-content-to-react'
import urlBuilder from "@sanity/image-url";
import PresStyles from '../components/presentation.module.css'

export const presData = graphql `
    query MyPresQuery {
        sanityPresentation {
          _rawPresEng
          _rawPresFr
          logosPartners {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
    }
`;
const urlFor = source =>
  urlBuilder({ projectId: "4iqgwgc6", dataset: "production" }).image(source);

const serializer = {
    types: {
      image: props => (
        <figure>
          <img
            src={urlFor(props.node.asset)
              //.width(600)
              .url()}
            alt={props.node.alt}
          />
  
        </figure>
      )
     }
  }
const Presentation = ({ data }) => { 
    const textData = data;
    console.log( data )
    const englishText = textData.sanityPresentation._rawPresEng;
    const frenchText = textData.sanityPresentation._rawPresFr;
    const logoPartImage = data.sanityPresentation.logosPartners.asset.fluid
    return(
    <div className="presentation-background">
    <div className="presentation-page">
        <Layout>
        <div className= "headerSpace"></div>
               <div className={PresStyles.content}>
                <div className={PresStyles.frenchContent}>           
                    <PortableText
                        blocks = { frenchText } 
                        serializers = { serializer }
                    />
                </div>
                <div className={PresStyles.englishContent}> 
                    <PortableText
                        blocks = { englishText } 
                        serializers = { serializer }
                    />
                 </div>   
                </div>
                
                <div className={PresStyles.logos}>
                  <h3>Partenaires</h3>
                    <Image fluid={ logoPartImage } alt={'gestalt'} />
                  <p>Design by Stéréo-buro, developement : Mark Webster</p>
                </div>
        </Layout>
    </div>
    </div>
    )
}

export default Presentation